@use 'styles/includes' as *;

.LinkListSlim {
    $root: &;

    padding: 4.5rem 0;
    background-color: $black;

    &__Container {
        @extend %container;
    }

    &__Title {
        margin: 0 0 3rem;
        font-family: $font-family-bold;
        font-size: 3rem;
        font-weight: $font-weight-bold;
        line-height: 100%;
        color: $white;
    }

    &__List {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 3rem;

        @include media(s) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include media(m) {
            grid-template-columns: repeat(4, 1fr);
        }

        @include media(l) {
            grid-template-columns: repeat(6, 1fr);
        }
    }

    &__Item {
        position: relative;
        text-align: center;
    }

    &__Image {
        position: relative;
        aspect-ratio: 1/1;
    }

    &__Link {
        @extend %card-link;
    }

    &__LinkTitle {
        margin: 1rem 0 0;
        font-family: $font-family-bold;
        font-weight: $font-weight-bold;
        font-size: 1.3rem;
        line-height: 100%;
        color: $white;
        display: inline-block;
    }
}
